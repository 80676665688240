<template>
  <main>
    <content-page-header>
      <div class="hdg-tab">
        <common-page-title title="アカウント情報" />
        <tab tab-count="1" :tab-name="state.tabName" @tabClick="tabClick" />
      </div>
    </content-page-header>
    <div class="content-body">
      <panel-body>
        <div class="edit-profile-panel">
          <div class="payment-information">
            <section>
              <common-block-title
                title="PAYMENT INFORMATION"
                subTitle="お支払い情報"
              />
              <div class="payment-view">
                <label class="form-function items-baseline">
                  <div class="form-input-label">プラン名</div>
                  <div class="w-full">
                    <p>
                      {{
                        store.state.user.servicePlanId == 1
                          ? "PREMIUM"
                          : "BUSINESS"
                      }}
                    </p>
                  </div>
                </label>
                <label class="form-function items-baseline">
                  <div class="form-input-label">価格</div>
                  <div class="w-full">
                    <p>
                      {{
                        store.state.user.servicePlanId == 1
                          ? "¥3,300/月（税込）"
                          : "〜¥16,500/月（税込）"
                      }}
                    </p>
                  </div>
                </label>
                <label class="form-function items-baseline">
                  <div class="form-input-label">利用アカウント数</div>
                  <div class="w-full">
                    <p>1アカウント</p>
                  </div>
                </label>
                <label class="form-function items-baseline">
                  <div class="form-input-label">お支払い方法</div>
                  <div class="w-full payment-way">
                    <div class="payment">クレジットカード払い</div>
                    <div class="card-info">
                      <span class="card-type">
                        <icon
                          v-if="cardInformation.card_brand_code === 'V'"
                          name="visa"
                        ></icon>
                        <icon
                          v-if="cardInformation.card_brand_code === 'M'"
                          name="master"
                        ></icon>
                        <icon
                          v-if="cardInformation.card_brand_code === 'A'"
                          name="american"
                        ></icon>
                        <icon
                          v-if="cardInformation.card_brand_code === 'J'"
                          name="jcb"
                        ></icon>
                        <icon
                          v-if="cardInformation.card_brand_code === 'D'"
                          name="diners"
                        ></icon> </span
                      ><span class="card-num"
                        >末尾 {{ cardInformation.card_number_last_four }}</span
                      >
                    </div>
                    <div class="card-period">
                      Expires {{ changeExpiredYm(cardInformation.expired_ym) }}
                    </div>
                  </div>
                </label>
                <label class="form-function items-baseline">
                  <div class="form-input-label">次回決済予定日</div>
                  <div class="w-full">
                    {{ display.toDate(next_payment_on) }}
                  </div>
                </label>
              </div>
            </section>
          </div>
          <div class="submit-area">
            <button
              class="submit-area-button submit-area-button--cancel"
              @click="back"
            >
              Back
            </button>
            <button
              class="submit-area-button submit-area-button--save"
              @click="submit"
            >
              お支払い方法の変更
            </button>
          </div>
        </div>
      </panel-body>
    </div>
    <form name="payService" :action="sbps.post_url" method="POST">
      <input
        type="hidden"
        name="merchant_id"
        :value="sbps.sbps_parameters?.merchant_id"
      />
      <input
        type="hidden"
        name="service_id"
        :value="sbps.sbps_parameters?.service_id"
      />
      <input
        type="hidden"
        name="cust_code"
        :value="sbps.sbps_parameters?.cust_code"
      />
      <input
        type="hidden"
        name="success_url"
        :value="sbps.sbps_parameters?.success_url"
      />
      <input
        type="hidden"
        name="cancel_url"
        :value="sbps.sbps_parameters?.cancel_url"
      />
      <input
        type="hidden"
        name="error_url"
        :value="sbps.sbps_parameters?.error_url"
      />
      <input
        type="hidden"
        name="pagecon_url"
        :value="sbps.sbps_parameters?.pagecon_url"
      />
      <input
        type="hidden"
        name="request_date"
        :value="sbps.sbps_parameters?.request_date"
      />
      <input
        type="hidden"
        name="sps_hashcode"
        :value="sbps.sbps_parameters?.sps_hashcode"
      />
    </form>
  </main>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, watch, computed, onMounted } from "vue";
import { useStore } from "vuex";
import http from "@/utils/http";
import display from "@/utils/display";
import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import Tab from "@/components/CommonTab/tab.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "editUserPassView",

  components: {
    contentPageHeader,
    CommonPageTitle,
    Tab,
    CommonBlockTitle,
    PanelBody,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      tabName: [
        {
          en: "PROFILE",
          ja: "プロフィール",
          isActive: false,
          methods: "profile",
          href: "/profile/update",
        },
        {
          en: "PAYMENT",
          ja: "お支払い",
          isActive: true,
          methods: "payment",
          href: "/profile/update",
        },
      ],
    });
    const isLogin = computed(() => store.state.user.isLogin);
    const isEnterprise = computed(() => store.state.user.isEnterprise);
    const servicePlanId = computed(() => store.state.user.servicePlanId);

    window.scrollTo(0, 0);

    const cardInformation = ref({});
    http
      .get(`/api/credit-cards/me`)
      .then((res) => {
        cardInformation.value = res.data;
      })
      .catch((error) => {
        console.log("エラー:", error);
      });

      // 次回決済予定日
      const next_payment_on = ref("")
      http
      .get(`/api/customers/me`)
      .then((res) => {
        next_payment_on.value = res.data.next_payment_on;
      })
      .catch((error) => {
        console.log("エラー:", error);
      });

    // SBPSサイトに遷移
    const sbps = ref({});
    const submit = () => {
      http
        .get(`/api/customers/payment-method/update/parameters`)
        .then((res) => {
          console.log(res.data);
          sbps.value = res.data;
          nextTick(() => {
            document.payService.submit();
          });
        })
        .catch((error) => {
          console.log("エラー:", error);
        });
    };

    const changeExpiredYm = (num) => {
      const mm = num?.substr(4);
      const yy = num?.substr(2, 2);
      return mm + "/" + yy;
    };

    const back = () => {
      router.push("/profile/update/");
      store.dispatch("changePaymentTab", 1);
    };
    const tabClick = (n) => {
      if (n === 0) {
        router.push("/profile/update/");
      }
    };

    watch(
      () => store.state.user.isLogin,
      async () => {
        if (!isLogin.value) {
          router.push("/");
          return;
        }
      }
    );
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        if (isEnterprise.value) {
          state.tabName.splice(1, 1);
        }
      }
    );
    onMounted(async () => {
      if (!isLogin.value || servicePlanId.value != 1) {
        router.push("/");
        return;
      }
    });

    return {
      store,
      state,
      cardInformation,
      next_payment_on,
      tabClick,
      back,
      display,
      changeExpiredYm,
      sbps,
      submit,
    };
  },
});
</script>

<style lang="scss" scoped>
.content-header {
  @include mq(md) {
    padding: 80px 20px;
  }
}
.hdg-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(md) {
    display: block;
  }
}
.tab-action {
  @include mq(sm) {
    margin-top: 60px;
  }
}
.content-body {
  position: relative;
  margin-top: -221px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  z-index: 1;
  @include mq(md) {
    margin-top: -590px;
  }
  @include mq(sm) {
    margin-top: -128px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.panel-body {
  margin-bottom: 50px;
  padding: 65px;
  background-color: $white;
  box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
  backdrop-filter: blur(10px);
  @include mq(sm) {
    padding: 60px 30px;
  }
}

.payment-view {
  position: relative;
  margin-top: 32.5px;
  padding-bottom: 32.5px;
  padding-top: 32.5px;
  @include mq(sm) {
    border-top: 0.5px solid $black;
    border-bottom: 0.5px solid $black;
    &::before {
      content: "";
      width: 60px;
      height: 4px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $black;
      transform: translateY(-50%);
    }
  }
}

.payment-way {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .payment {
    margin-right: 35px;
    @include mq(sm) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .card-info {
    display: flex;
    align-items: center;
    margin-right: 35px;
    padding-right: 35px;
    border-right: 1px solid $black;
    .card-type {
      margin-right: 20px;
      @include mq(sm) {
        margin-right: 10px;
      }
      .icon {
        width: 50px;
        height: 30px;
      }
    }
    @include mq(sm) {
      margin-right: 0;
      padding-right: 10px;
    }
  }
  .card-period {
    @include mq(sm) {
      padding-left: 10px;
    }
  }
}
p,
.payment,
.card-info,
.card-period {
  @include mq(sm) {
    font-size: 14px;
  }
}
.form-function {
  @include mq(sm) {
    &:nth-of-type(n + 2) {
      position: relative;
      margin-top: 15px;
      &:before {
        content: "";
        width: 30px;
        height: 0.5px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $black;
      }
    }
  }
}
.form-input-label {
  @include mq(sm) {
    font-size: 12px;
    color: rgba(89, 88, 87, 0.8);
  }
}
</style>